// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"87f45ebaec14f0f56fa6981d8f89e11c2e02356b"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://ebaa6d4cfb152ab3a6002b77e2807947@o4508009407774720.ingest.us.sentry.io/4508455311376384',

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: process.env.NODE_ENV === 'production',
});
